<template>
  <div>
    <v-tabs
      v-model="tab"
      grow
      show-arrows
      background-color="grey lighten-2"
    >
      <v-tab href="#korrektur">Notenkorrektur</v-tab>
      <v-tab href="#wkbericht">WK-Bericht</v-tab>
      <v-tab href="#pdf">PDF</v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      touchless
    >
      <v-tab-item value="korrektur"><korrektur /></v-tab-item>
      <v-tab-item value="wkbericht"><wkbericht :e="e" :r="r" :df="df" :id="id" /></v-tab-item>
      <v-tab-item value="pdf"><pdf :e="e" :r="r" :df="df" :id="id" /></v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: 'nach',

  components: {
    Korrektur: () => import('./nach/korrektur'),
    Wkbericht: () => import('./nach/wkbericht'),
    Pdf: () => import('./nach/pdf')
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    f: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    tab: 'status'
  })
}
</script>

<style scoped>

</style>
